<template>
  <div class="edit-questions-and-answers-box">
    <div class="cell-b">
      <selfCell title="展示頂部圖片">
        <el-switch
          v-model="configs.showTopImg"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="setValue('showTopImg')"
        >
        </el-switch>
      </selfCell>
    </div>
    <template v-if="type != 'btn'">
      <template v-for="(item, cindex) in configs.items">
        <div
          v-if="type != 'btn' && (index === '' || index == cindex)"
          :key="cindex"
          class="cell-b"
        >
          <h4>
            Q&A模塊 第({{ cindex + 1 }})個
            <el-button-group class="btn-group">
              <el-button
                type="primary"
                icon="el-icon-upload2"
                :disabled="cindex == 0"
                size="mini"
                @click="itemCheck('items', cindex, 0, 0, cindex - 1)"
              ></el-button>
              <el-button
                type="success"
                icon="el-icon-download"
                :disabled="cindex == configs.items.length - 1"
                size="mini"
                @click="itemCheck('items', cindex, 0, 0, cindex + 1)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="configs.items.length == 1"
                @click="itemDel('items', cindex)"
              ></el-button>
              <el-button
                type="warning"
                icon="el-icon-plus"
                size="mini"
                @click="itemAdd('items', cindex)"
              ></el-button>
            </el-button-group>
          </h4>
          <div class="cell-b">
            <selfCell title="內容" :column="true">
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="
                  item.question[`${lItem.code == 'HK' ? '' : lItem.code}text`]
                "
                :placeholder="`請輸入標題-${lItem.name}`"
                @input="setValue('items')"
                type="textarea"
                rows="3"
              ></el-input>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="item.question.color"
                @change="setValue('items')"
              />
            </selfCell>
            <selfCell title="背景色">
              <el-color-picker
                v-model="item.question.bgColor"
                @change="setValue('items')"
              />
            </selfCell>
            <selfFontSize
              title="字體大小"
              v-model="item.question.fontSize"
              @change="setValue('items')"
            />
            <selfFontSize
              title="移動端字體大小"
              v-model="item.question.fontSizeByPhone"
              @change="setValue('items')"
            />
          </div>

          <h3>內容設置</h3>
          <div class="cell-b">
            <selfCell title="樣式">
              <el-select v-model="item.answer.type" @change="setValue('items')">
                <el-option label="文本" value="1"></el-option>
                <el-option label="圖片" value="2"></el-option>
                <el-option label="表格" value="3"></el-option>
              </el-select>
            </selfCell>
            <!-- 文本 -->
            <div v-if="item.answer.type == 1">
              <div>
                <div
                  style="width: 100%"
                  v-for="lItem in language"
                  :key="lItem.code"
                >
                  <el-input
                    v-model="
                      item.answer.text[
                        `${lItem.code == 'HK' ? '' : lItem.code}content`
                      ]
                    "
                    :placeholder="`請輸入標題-${lItem.name}`"
                    @input="setValue('items')"
                    type="textarea"
                    rows="5"
                  />
                </div>
              </div>
              <selfFontSize
                title="字體大小"
                v-model="item.answer.text.fontSize"
                @change="setValue('items')"
              />
              <selfFontSize
                title="移動端字體大小"
                v-model="item.answer.text.fontSizeByPhone"
                @change="setValue('items')"
              />
            </div>
            <!-- 圖片 -->
            <div v-else-if="item.answer.type == 2">
              <selfUpload
                v-model="item.answer.img.src"
                type="default"
                @change="setValue('items')"
              />
            </div>
            <!-- 表格 -->
            <div v-else-if="item.answer.type == 3">
              <selfCell title="線條樣式">
                <el-select
                  v-model="item.answer.table.type"
                  @change="setValue('items')"
                >
                  <el-option label="樣式1" value="1"></el-option>
                  <el-option label="樣式2" value="2"></el-option>
                </el-select>
              </selfCell>
              <!-- 表頭 -->
              <div class="cell-b">
                <template v-for="(trItem, trIndex) in item.answer.table.trs">
                  <div class="cell-b">
                    <h3>
                      {{
                        trItem.type == 'th'
                          ? '表頭(tr/th)'
                          : `表體(tr/td:第${trIndex}行)`
                      }}

                      <el-button-group
                        v-if="trItem.type == 'td'"
                        class="btn-group"
                      >
                        <el-button
                          type="primary"
                          icon="el-icon-upload2"
                          :disabled="trIndex == 0"
                          size="mini"
                          @click="
                            itemCheck('tr', cindex, trIndex, 0, trIndex - 1)
                          "
                        ></el-button>
                        <el-button
                          type="success"
                          icon="el-icon-download"
                          :disabled="
                            trIndex == item.answer.table.trs.length - 1
                          "
                          size="mini"
                          @click="
                            itemCheck('tr', cindex, trIndex, 0, trIndex + 1)
                          "
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          size="mini"
                          :disabled="item.answer.table.trs.length == 1"
                          @click="itemDel('tr', cindex, trIndex, 0)"
                        ></el-button>
                        <el-button
                          type="warning"
                          icon="el-icon-plus"
                          size="mini"
                          @click="itemAdd('tr', cindex, trIndex, 0)"
                        ></el-button>
                      </el-button-group>
                    </h3>
                    <selfCell
                      title="總背景色"
                      tipContent="控制tr整行背景色,將覆蓋td|th單獨設置"
                    >
                      <el-color-picker
                        v-model="trItem.bgColor"
                        @change="setValue('items')"
                      />
                    </selfCell>
                    <div
                      v-for="(thItem, thIndex) in trItem.child"
                      :key="thIndex"
                      class="cell-b"
                    >
                      <!-- 操作 -->
                      <el-button-group class="btn-group">
                        <el-button
                          type="primary"
                          icon="el-icon-upload2"
                          :disabled="thIndex == 0"
                          size="mini"
                          @click="
                            itemCheck(
                              'td',
                              cindex,
                              trIndex,
                              thIndex,
                              thIndex - 1
                            )
                          "
                        ></el-button>
                        <el-button
                          type="success"
                          icon="el-icon-download"
                          :disabled="thIndex == trItem.child.length - 1"
                          size="mini"
                          @click="
                            itemCheck(
                              'td',
                              cindex,
                              trIndex,
                              thIndex,
                              thIndex + 1
                            )
                          "
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          size="mini"
                          :disabled="trItem.child.length == 1"
                          @click="itemDel('td', cindex, trIndex, thIndex)"
                        ></el-button>
                        <el-button
                          type="warning"
                          icon="el-icon-plus"
                          size="mini"
                          @click="itemAdd('td', cindex, trIndex, thIndex)"
                        ></el-button>
                      </el-button-group>
                      <selfCell title="單元格" :column="true">
                        <el-input
                          v-for="lItem in language"
                          :key="lItem.code"
                          v-model="
                            thItem[
                              `${lItem.code == 'HK' ? '' : lItem.code}text`
                            ]
                          "
                          :placeholder="`請輸入標題-${lItem.name}`"
                          @input="setValue('items')"
                          type="textarea"
                          rows="3"
                        ></el-input>
                      </selfCell>
                      <selfCell
                        v-if="trItem.type == 'th'"
                        title="寬度"
                        tipContent="整行寬度總值為100%"
                      >
                        <el-select
                          v-model="thItem.width"
                          @change="setValue('items')"
                        >
                          <el-option
                            v-for="bfb in [10, 20, 30, 40, 50, 60, 80, 90, 100]"
                            :key="bfb"
                            :label="bfb + '%'"
                            :value="bfb + '%'"
                          ></el-option>
                        </el-select>
                      </selfCell>

                      <selfCell title="背景色">
                        <el-color-picker
                          v-model="thItem.bgColor"
                          @change="setValue('items')"
                        />
                      </selfCell>
                      <selfFontSize
                        title="字體大小"
                        v-model="thItem.fontSize"
                        @change="setValue('items')"
                      />
                      <selfCell title="字體色">
                        <el-color-picker
                          v-model="thItem.color"
                          @change="setValue('items')"
                        />
                      </selfCell>
                      <selfCell v-if="trItem.type == 'td'" title="行合併">
                        <el-select
                          v-model="thItem.rowspan"
                          @change="setValue('items')"
                        >
                          <el-option
                            v-for="bfb in [
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20,
                            ]"
                            :key="bfb + 'row' + trIndex"
                            :label="bfb"
                            :value="bfb"
                          ></el-option>
                        </el-select>
                      </selfCell>
                      <selfCell title="文字對齊">
                        <el-select
                          v-model="thItem.textAlign"
                          @change="setValue('items')"
                        >
                          <el-option
                            v-for="ta in ['left', 'center', 'right']"
                            :key="ta"
                            :label="ta"
                            :value="ta"
                          ></el-option>
                        </el-select>
                      </selfCell>
                    </div>
                  </div>
                </template>
              </div>
              <!-- 內容 -->
            </div>
          </div>
        </div>
      </template>
    </template>
    <div v-if="type == 'btn' || type == 'box'" class="cell-b">
      <h4>按鈕配置</h4>
      <selfCell title="圖標">
        <selfUpload
          v-model="configs.btn.icon"
          type="default"
          @change="setValue('btn')"
        />
      </selfCell>
      <selfCell title="內容" :column="true">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs.btn[`${lItem.code == 'HK' ? '' : lItem.code}text`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue('btn')"
          type="textarea"
          rows="3"
        ></el-input>
      </selfCell>
      <selfCell title="文字顏色">
        <el-color-picker
          v-model="configs.btn.color"
          @change="setValue('btn')"
        />
      </selfCell>
      <selfCell title="背景色">
        <el-color-picker
          v-model="configs.btn.bgColor"
          @change="setValue('btn')"
        />
      </selfCell>
      <selfFontSize
        title="字體大小"
        v-model="configs.btn.fontSize"
        @change="setValue('btn')"
      />
      <selfFontSize
        title="移動端字體大小"
        v-model="configs.btn.fontSizeByPhone"
        @change="setValue('btn')"
      />
      <selfCell title="點擊跳轉">
        <selfPagePicker v-model="configs.btn.go" @onChange="setValue('btn')" />
      </selfCell>
    </div>
    <div v-if="type == 'btn' || type == 'box'" class="cell-b">
      <h4>whatsapp配置</h4>
      <selfCell title="圖標">
        <selfUpload
          v-model="configs.whatsapp.icon"
          type="default"
          @change="setValue('whatsapp')"
        />
      </selfCell>
      <selfCell title="點擊跳轉">
        <selfPagePicker
          v-model="configs.whatsapp.go"
          @onChange="setValue('whatsapp')"
        />
      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit';
import { getByName } from '@/js/utils/default_config';

export default {
  name: 'edit-questions-and-answers',
  mixins: [langEditMixin],
  data() {
    return {
      isEdit: false,
      init: {
        height: 500,
        menubar: false,
        plugins: [
          'a11ychecker',
          'advlist',
          'advcode',
          'advtable',
          'autolink',
          'checklist',
          'export',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'powerpaste',
          'fullscreen',
          'formatpainter',
          'insertdatetime',
          'media',
          'table',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | casechange blocks | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlst checklist outdent indent | removeformat | a11ycheck code table help',
      },
    };
  },
  props: {
    index: {
      default() {
        return '';
      },
    },
    configs: {
      default() {
        return {};
      },
    },
    type: {
      default() {
        return '';
      },
    },
  },
  created() {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    setValue(key) {
      if (key == 'src') key = 'items';
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    // 切換順序
    itemCheck(type, index, trIndex, thIndex, newIndex) {
      let items = this.configs.items;
      let trs = items[index].answer.table.trs;
      let tr = trs[trIndex];
      if (type == 'items') {
        let _ = items.splice(index, 1);
        items.splice(newIndex, 0, _[0]);
      } else if (type == 'tr') {
        let _ = trs.splice(trIndex, 1);
        trs.splice(newIndex, 0, _[0]);
      } else {
        let ths = tr.child;
        let _ = ths.splice(thIndex, 1);
        ths.splice(newIndex, 0, _[0]);
      }
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel(type, index, trIndex, thIndex) {
      let items = this.configs.items;
      let trs = items[index].answer.table.trs;
      let tr = trs[trIndex];
      if (type == 'items') {
        items.splice(index, 1);
      } else if (type == 'tr') {
        trs.splice(trIndex, 1);
      } else {
        let ths = tr.child;
        ths.splice(thIndex, 1);
      }
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd(type, index, trIndex, thIndex) {
      let items = this.configs.items;
      let trs = items[index].answer.table.trs;
      let tr = trs[trIndex];
      if (type == 'items') {
        items.splice(index + 1, 0, this.$util.object.clone(items[index]));
      } else if (type == 'tr') {
        trs.splice(trIndex + 1, 0, this.$util.object.clone(trs[trIndex]));
      } else {
        let ths = tr.child;
        ths.splice(thIndex + 1, 0, this.$util.object.clone(ths[thIndex]));
      }
      this.$emit('setValue', { key: 'items', value: items });
    },
  },
};
</script>

<style lang="less" scoped></style>
